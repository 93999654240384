import { NullCheck } from "Utils/validator";

// Inputs to add users
export const inputs = [
    {
        label: "First name",
        name: "firstName",
        validate: NullCheck,
        type: "text",
    },
    {
        label: "Last name",
        name: "lastName",
        validate: NullCheck,
        type: "text",
    },
    {
        label: "Mobile Number",
        name: "mobileNumber",
        validate: NullCheck,
        type: "number",
    },
    {
        label: "Email",
        name: "emailId",
        validate: NullCheck,
        type: "email",
    },
];

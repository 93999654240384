import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function Load() {
    return (
        <SkeletonTheme>
            <div className={"Table"}>
                <table>
                    <thead>
                        <tr>
                            <th>Topic Name</th>
                            <th>Link</th>
                            <th>Created At</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array(3)
                            .fill(3)
                            .map((_, id) => (
                                <tr key={id}>
                                    <td>
                                        <Skeleton height={"1rem"} />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>

                                    <td>
                                        <Skeleton />
                                    </td>
                                    <td>
                                        <Skeleton />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </SkeletonTheme>
    );
}

export default Load;

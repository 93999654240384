import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import { FiEdit2 } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import { AiOutlinePlus, AiFillDelete } from "react-icons/ai";
import Moment from "react-moment";

import useStore from "Store/store";
import { Toast } from "Utils/util";
import Layout from "Component/Layout";
import Loader from "Pages/CourseVideos/videos/loader";
import Preview from "Pages/CourseVideos/videos/preview";
import { Course_Videos, Delete_video } from "Service/ServiceCall";

function Videos() {
    const Navigate = useNavigate();
    const [load, setLoad] = useState(true);
    const [show, setShow] = useState("");
    const [Videos, setVideos] = useState([]);
    const [params, setParams] = useSearchParams();
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setshowPaginate] = useState(false);
    const NoOfRecords = useStore((item) => item.NoOfRecords);
    const [forcepage, setforcepage] = useState(0);

    const getAllVideos = async (courseId: number, offset: number) => {
        try {
            const allvideos: any = await Course_Videos(courseId, offset);
            if (allvideos.Status === 1) {
                setVideos(allvideos.CourseVideos);

                if (allvideos.VideosCount > NoOfRecords) {
                    setpageCount(
                        Math.ceil(
                            Number(allvideos?.VideosCount) / Number(NoOfRecords)
                        )
                    );

                    setshowPaginate(true);
                }
            }
        } catch (err) {
            alert("Something went wrong try again");
            console.error("An error occurred:", err);
        } finally {
            setTimeout(() => {
                setLoad(false);
            }, 200);
        }
    };
    useEffect(() => {
        let page = Number(params.get("page"));
        let courseId = Number(params.get("courseId"));

        if (page) {
            getAllVideos(courseId, (page - 1) * NoOfRecords);
            setforcepage(page - 1);
        }
        // eslint-disable-next-line
    }, []);
    async function DeleteVideo(CourseId: number) {
        try {
            const Delete: any = await Delete_video(CourseId);
            if (Delete.Status === 1) {
                Toast(Delete.Message, "success");
                let page = Number(params.get("page"));
                let courseId = Number(params.get("courseId"));
                if (Videos.length === 1) {
                    setParams({ page: String(page - 1) });
                    page = (page - 2) * NoOfRecords;
                    setforcepage(page - 2);
                } else {
                    setforcepage(page - 1);
                    page = (page - 1) * NoOfRecords;
                }

                getAllVideos(courseId, page);
            }
        } catch (err) {
            alert("something went Wrong try Aagin");
        }
    }

    const deleteHandler = (event: React.MouseEvent) => {
        let id = event.currentTarget.getAttribute("custom-id");
        swal("Are you sure you want to Delete Video", {
            icon: "warning",
            dangerMode: true,
            buttons: {
                catch: {
                    text: "Delete",
                    value: "Complete",
                    className: "sweet-alert-btn",
                },
                defeat: false,
            },
        }).then((value: any) => {
            switch (value) {
                case "Complete":
                    DeleteVideo(Number(id));
                    break;
            }
        });
    };
    const EditHandler = (event: React.MouseEvent) => {
        let id = event.currentTarget.getAttribute("custom-id");
        let courseId = Number(params.get("courseId"));
        const [selectedVideo] = Videos.filter(
            (item: any) => item.videoId === Number(id)
        );
        sessionStorage.setItem("selectedVideo", JSON.stringify(selectedVideo));
        Navigate(
            `/Admin/Courses/videos/edit?videoId=${id}&courseId=${courseId}`
        );
    };

    const handlePageChange = (selectedObject: any) => {
        setParams({ page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "smooth" });
        setforcepage(selectedObject.selected);
        let courseId = Number(params.get("courseId"));
        getAllVideos(courseId, selectedObject.selected * NoOfRecords);
        setLoad(true);
    };
    function BackButton() {
        Navigate(-1);
    }
    return (
        <Layout Active={"Courses"}>
            {show.length > 0 && <Preview show={show} setShow={setShow} />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>Videos</title>
            </Helmet>
            <div className={"Container"}>
                <div className="Action-btn">
                    <button onClick={BackButton} className={"back"}>
                        Back
                    </button>
                    <NavLink
                        to={`/Admin/Courses/videos/add?courseId=${params.get(
                            "courseId"
                        )}`}>
                        {" "}
                        <AiOutlinePlus />
                        <span>Create new Video</span>
                    </NavLink>
                </div>
                <div className={"sub-wrapper"}>
                    <h3>Videos</h3>
                    {load ? (
                        <Loader />
                    ) : (
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Topic Name</th>
                                        <th>Link</th>
                                        <th>Created At</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Videos.length > 0 ? (
                                        Videos.map((video: any, id: any) => (
                                            <tr key={id}>
                                                <td>{video.topicName}</td>
                                                <td>
                                                    {" "}
                                                    {video.videoURL !== "NA" ? (
                                                        <button
                                                            onClick={() =>
                                                                setShow(
                                                                    video.videoURL
                                                                )
                                                            }>
                                                            Click
                                                        </button>
                                                    ) : (
                                                        "NA"
                                                    )}
                                                </td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {
                                                            new Date(
                                                                video.createdAt
                                                            )
                                                        }
                                                    </Moment>
                                                </td>

                                                <td>
                                                    {video.status === 1 ? (
                                                        <span
                                                            className={
                                                                "active-status"
                                                            }>
                                                            Active
                                                        </span>
                                                    ) : (
                                                        <span
                                                            className={
                                                                "inactive-status"
                                                            }>
                                                            Inactive
                                                        </span>
                                                    )}
                                                </td>
                                                <td>
                                                    <div
                                                        className={
                                                            "Action-buttons"
                                                        }>
                                                        <button
                                                            custom-id={
                                                                video.videoId
                                                            }
                                                            onClick={
                                                                EditHandler
                                                            }>
                                                            <FiEdit2
                                                                size={20}
                                                            />
                                                        </button>
                                                        <button
                                                            onClick={
                                                                deleteHandler
                                                            }
                                                            custom-id={
                                                                video.videoId
                                                            }>
                                                            <AiFillDelete
                                                                size={20}
                                                                color="red"
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>No Data Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Videos;

import { create } from 'zustand'

const initialState = {
 showNavbar: true,
 shouldAnimate: false,
 NoOfRecords: 10,
};
const useStore = create((set) => ({
 ...initialState,
 setshowNavbar: () =>
  set((state) => ({ ...state, showNavbar: !state.showNavbar })),
 setToken: (data) => set((state) => ({ ...state, Token: data })),
 setShouldAnimate: (data) => set((state) => ({ ...state, shouldAnimate: data })),

}));
export default useStore;

import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

export const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

const sendRequest = async <T>(
    method: string,
    endpoint: string,
    data?: any
): Promise<T> => {
    try {
        const config: AxiosRequestConfig = {
            method,
            url: endpoint,
            data,
        };

        const response: AxiosResponse<T> = await api.request(config);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default sendRequest;

import React from "react";
import { FormControlProps } from "Utils/Types/Types";

const FormControl: React.FC<FormControlProps> = ({
    label,
    name,
    register,
    errors,
    validate,
    type = "text",
    isTextarea = false,
    options = [], // Default empty array for options
}) => {
    return (
        <div className={"form_control"}>
            <label htmlFor={name}>{label}</label>
            {isTextarea ? (
                <textarea
                    rows={5}
                    {...register(name, {
                        required: "this field is required",
                        validate: validate,
                    })}
                />
            ) : options.length > 0 ? (
                <select
                    defaultValue={""}
                    {...register(name, {
                        required: "this field is required",
                        validate: validate,
                    })}>
                    {options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            ) : (
                <input
                    {...register(name, {
                        required: "this field is required",
                        validate: validate,
                    })}
                    type={type}
                />
            )}
            {errors[name] && <span>{errors[name].message}</span>}
        </div>
    );
};

export default FormControl;

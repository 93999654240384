import { useLayoutEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.scss";
import Login from "Pages/Login";
import AdminRoutes from "Routes/Routes";
import { api } from "Service/Base";
import Guard from "Utils/Auth/Guard";
import AcessDenied from "Pages/AccessDenied/denied";
function App() {
    useLayoutEffect(() => {
        const storedToken = localStorage.getItem("authToken");
        if (storedToken) {
            // Set the token in Axios default headers
            api.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${storedToken}`;
        }
    }, []);
    return (
        <>
            <ToastContainer />
            <Routes>
                <Route path="/" element={<Login />} />
                <Route element={<Guard />}>
                    <Route path="/Admin/*" element={<AdminRoutes />} />
                </Route>
                <Route path="*" element={<AcessDenied />} />
            </Routes>
        </>
    );
}

export default App;

import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";

import { Toast } from "Utils/util";
import Layout from "Component/Layout";
import { NullCheck } from "Utils/validator";
import { CourseTypes } from "Utils/Types/Types";
import { Add_Course, Edit_Course } from "Service/ServiceCall";
import FormControl from "Component/reusable/Forminput";

function Addcourse() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    // eslint-disable-next-line
    const [edit, setEdit] = useState<Boolean>(false);
    const [load, setLoad] = useState<Boolean>(false);
    const [error, SetError] = useState("");
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<CourseTypes>();

    useEffect(() => {
        let selectedCourse = sessionStorage.getItem("selectedCourse");
        if (params.get("id") && selectedCourse) {
            setEdit(true);
            reset(JSON.parse(selectedCourse));
        }
        // eslint-disable-next-line
    }, []);

    // Handle Page Navigation
    function BackButton() {
        Navigate(-1);
    }

    // Form Sumit
    const onSubmit: SubmitHandler<CourseTypes> = async (data) => {
        setLoad(true);
        try {
            if (!edit) {
                const addCourse: any = await Add_Course(data);
                if (addCourse.Status === 1) {
                    Toast(addCourse.Message, "success");
                    BackButton();
                }
            } else {
                let CourseId = Number(params.get("id"));
                const EditCourse: any = await Edit_Course(
                    data,
                    Number(CourseId)
                );
                if (EditCourse.Status === 1) {
                    sessionStorage.removeItem("selectedCourse");
                    Toast(EditCourse.Message, "success");
                    Navigate(-1);
                }
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                SetError(error.response.data.Message);
            } else {
                alert("Something went wrong try again");
                console.error("An error occurred:", error);
            }
        } finally {
            setLoad(false);
        }
    };
    return (
        <Layout Active={"Courses"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Course</title>
            </Helmet>
            <div className={"Container"}>
                <button onClick={BackButton} className={"back"}>
                    Back
                </button>
                <div className={"form_container"}>
                    <h3>{!edit ? "Add Course" : "Update Course"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                            label="Course Name"
                            name="courseName"
                            register={register}
                            errors={errors}
                            validate={NullCheck}
                        />
                        <FormControl
                            label="Description"
                            name="description"
                            register={register}
                            errors={errors}
                            validate={NullCheck}
                            isTextarea={true}
                        />

                        {edit && (
                            <FormControl
                                label="Status"
                                name="status"
                                register={register}
                                errors={errors}
                                validate={NullCheck}
                                options={[
                                    { value: "1", label: "Active" },
                                    { value: "2", label: "inactive" },
                                ]}
                            />
                        )}
                        {error.length > 0 && (
                            <span style={{ color: "red" }}>{error}</span>
                        )}
                        <div className={"submit"}>
                            {load ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                <button>Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcourse;

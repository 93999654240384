import LayoutClasses from "./Layout.module.css";
import SideNav from "Component/Navbar/SideNav";
import TopNav from "Component/Navbar/TopNav";
import useStore from "Store/store";
function Layout({ children, Active }) {
    const showNav = useStore((state) => state.showNavbar);
    return (
        <div className={LayoutClasses["Wrapper"]}>
            <SideNav Active={Active} />

            <div
                className={`${LayoutClasses["main-content"]} ${
                    !showNav ? LayoutClasses["nav-hide"] : ""
                }`}>
                <TopNav />
                <div style={{ zIndex: "2" }}>{children}</div>
            </div>
        </div>
    );
}

export default Layout;

import { IoIosBook } from "react-icons/io";
import { FaUser, FaBookReader } from "react-icons/fa";
export const Menu = [
    {
        id: 1,
        name: "Courses",
        menuLink: "/Admin/Courses?page=1",
        active: "Courses",
        icon: IoIosBook,
        role: 2,
    },
    {
        id: 2,
        name: "Users",
        menuLink: "/Admin/Users?page=1",
        active: "Users",
        icon: FaUser,
    },
    {
        id: 3,
        name: "Enrollment",
        menuLink: "/Admin/Enrollment?page=1",
        active: "Enroll",
        icon: FaBookReader,
    },
];

import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
import { useForm, SubmitHandler } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";

import { Toast } from "Utils/util";
import { inputs } from "./helper";
import Layout from "Component/Layout";
import { NullCheck } from "Utils/validator";
import { UserTypes } from "Utils/Types/Types";
import { Add_User, Edit_User } from "Service/ServiceCall";
import FormControl from "Component/reusable/Forminput";

function Addcourse() {
    const Navigate = useNavigate();
    // eslint-disable-next-line
    const [edit, setEdit] = useState<Boolean>(false);
    const [load, setLoad] = useState<Boolean>(false);
    const [error, SetError] = useState("");
    const [params] = useSearchParams();
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<UserTypes>();

    useEffect(() => {
        let selectedUser = sessionStorage.getItem("SelectedUser");
        if (params.get("id") && selectedUser) {
            setEdit(true);
            reset(JSON.parse(selectedUser));
        }
        // eslint-disable-next-line
    }, []);
    // Handle Page Navigation
    function BackButton() {
        Navigate(-1);
    }

    // Form Sumit
    const onSubmit: SubmitHandler<UserTypes> = async (data) => {
        setLoad(true);
        try {
            if (!edit) {
                const addUser: any = await Add_User(data);
                if (addUser.Status === 1) {
                    Toast(addUser.Message, "success");
                    BackButton();
                }
            } else {
                let UserId = Number(params.get("id"));
                const EditUser: any = await Edit_User(data, Number(UserId));
                if (EditUser.Status === 1) {
                    sessionStorage.removeItem("selectedUser");
                    Toast(EditUser.Message, "success");
                    Navigate(-1);
                }
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                SetError(error.response.data.Message);
            } else {
                alert("Something went wrong try again");
                console.error("An error occurred:", error);
            }
        } finally {
            setLoad(false);
        }
    };
    return (
        <Layout Active={"Users"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Users</title>
            </Helmet>
            {/* <input type="radio" checked name="" id="" /> */}
            <div className={"Container"}>
                <button onClick={BackButton} className={"back"}>
                    Back
                </button>
                <div className={"form_container"}>
                    <h3>{!edit ? "Add User" : "Update User"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {inputs.map((field, id) => (
                            <FormControl
                                key={id}
                                label={field.label}
                                name={field.name}
                                register={register}
                                errors={errors}
                                validate={field.validate}
                                type={field.type}
                            />
                        ))}
                        {edit && (
                            <FormControl
                                label="Status"
                                name="Status"
                                register={register}
                                errors={errors}
                                validate={NullCheck}
                                options={[
                                    { value: "1", label: "Active" },
                                    { value: "2", label: "inactive" },
                                ]}
                            />
                        )}
                        {error.length > 0 && (
                            <span style={{ color: "red" }}>{error}</span>
                        )}
                        <div className={"submit"}>
                            {load ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                <button>Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcourse;

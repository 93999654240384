import User from "Assets/user.svg";
import TopNavClasses from "Component/Navbar/TopNav/TopNavClasses.module.scss";
function TopNav() {
    return (
        <div className={TopNavClasses["TopNav"]}>
            <div className={TopNavClasses["user"]}>
                <img src={User} alt="user" />
            </div>
        </div>
    );
}

export default TopNav;

import React, { useState, useEffect, useLayoutEffect } from "react";
import { NavLink } from "react-router-dom";
import swal from "sweetalert";
import { GiHamburgerMenu } from "react-icons/gi";
import { HiOutlineLogout } from "react-icons/hi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import navbarClasses from "Component/Navbar/SideNav/Navclass.module.scss";
import { Menu } from "Component/Navbar/SideNav/Menu";
import useStore from "Store/store";
import Logo from "Assets/logo.jpg";
import { SideNavProps } from "Utils/Types/Types";
import { useNavigate } from "react-router-dom";
const SideNav: React.FC<SideNavProps> = ({ Active }) => {
    const [menuitems, setMenu] = useState(Menu);
    const Navigate = useNavigate();
    const { setshowNavbar, showNavbar } = useStore((state) => state);
    const [screenSize, getDimension] = useState({
        dynamicWidth: window.innerWidth,
        dynamicHeight: window.innerHeight,
    });
    useLayoutEffect(() => {
        let AdminData = JSON.parse(localStorage.getItem("AdminData"));
        if (AdminData.role == 2) {
            setMenu(Menu.filter((item) => item.role === 2));
        }
    }, []);
    useEffect(() => {
        window.addEventListener("resize", setDimension);

        return () => {
            window.removeEventListener("resize", setDimension);
        };
        // eslint-disable-next-line
    }, [screenSize]);

    function AutoCloseNavbar() {
        if (window.innerWidth <= 900 && showNavbar) {
            setshowNavbar(false);
        }
    }

    function setDimension() {
        getDimension({
            dynamicWidth: window.innerWidth,
            dynamicHeight: window.innerHeight,
        });
        AutoCloseNavbar();
    }

    function ToggleSideNav() {
        setshowNavbar((prev: any) => !prev);
    }
    const logoutHandler = () => {
        swal("Are you sure you want to Logout?", {
            icon: "warning",
            dangerMode: true,
            buttons: {
                catch: {
                    text: "Logout",
                    value: "Complete",
                    className: "sweet-alert-btn",
                },
                defeat: false,
            },
        }).then((value: any) => {
            switch (value) {
                case "Complete":
                    localStorage.clear();
                    sessionStorage.clear();
                    document.title = "Odigos";
                    Navigate("/");

                    break;
            }
        });
    };

    return (
        <div>
            <div
                className={`${showNavbar ? navbarClasses["backdrop"] : ""}`}
                onClick={ToggleSideNav}></div>
            <div
                className={`${navbarClasses["Container"]} ${
                    !showNavbar ? navbarClasses["hide"] : ""
                }`}>
                <div
                    className={`${navbarClasses["hamburger"]} ${
                        !showNavbar ? navbarClasses["hamburgershow"] : ""
                    }`}>
                    <button
                        className={navbarClasses["hamburger-icon"]}
                        onClick={ToggleSideNav}>
                        <GiHamburgerMenu size={22} color="white" />
                    </button>
                    <img
                        className={!showNavbar ? navbarClasses["img-size"] : ""}
                        src={Logo}
                        alt="logo"
                        onClick={() => {
                            window.open("/", "_blank", "noopener,noreferrer");
                        }}
                    />
                </div>

                <div className={navbarClasses["navlinks"]}>
                    {menuitems.map((item: any) => (
                        <div key={item.id}>
                            <NavLink
                                key={item.id}
                                id={item.id}
                                to={item.menuLink}
                                data-tooltip-id={item.id}
                                data-tooltip-content={item.name}
                                data-tooltip-position-strategy={"fixed"}
                                className={`${
                                    Active === item.active
                                        ? navbarClasses["navActive"]
                                        : navbarClasses["navInactive"]
                                } ${
                                    showNavbar
                                        ? navbarClasses["link-show"]
                                        : navbarClasses["link-hide"]
                                }`}>
                                <item.icon
                                    className={
                                        !showNavbar
                                            ? navbarClasses["nav-icon"]
                                            : navbarClasses["nav-icon-small"]
                                    }
                                />
                                {showNavbar && <span>{item.name}</span>}
                            </NavLink>{" "}
                            <ReactTooltip
                                id={item.id}
                                place="left"
                                variant="info"
                                hidden={showNavbar}
                            />
                        </div>
                    ))}
                </div>
                <button
                    className={navbarClasses["logout"]}
                    onClick={logoutHandler}>
                    <HiOutlineLogout size={23} title="Layout" />
                    {showNavbar && <p>Logout</p>}
                </button>
            </div>
        </div>
    );
};

export default SideNav;

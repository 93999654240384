import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { Toast } from "Utils/util";
import Layout from "Component/Layout";
import { enrollment } from "Utils/Types/Types";
import {
    Add_enrollment,
    Get_All_Course_noOffset,
    All_Users_noOffset,
} from "Service/ServiceCall";
import FormControl from "Component/reusable/Forminput";

function Addcourse() {
    const Navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [users, setUsers] = useState([]);
    const [load, setLoad] = useState<Boolean>(false);
    const [error, SetError] = useState("");
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<enrollment>();

    const getAllUsers = async () => {
        try {
            const All_Users: any = await All_Users_noOffset();
            if (All_Users.Status === 1) {
                const users = All_Users.Users.map((item: any) => {
                    return {
                        label: `${item.firstName} ${
                            item.lastName !== "NA" ? item.lastName : ""
                        }`,
                        value: item.userId,
                    };
                });
                setUsers([{ value: "", label: "Select users" }, ...users]);
            }
        } catch (err) {
            alert("Something went wrong try again");
            console.error("An error occurred:", err);
        } finally {
            setTimeout(() => {
                setLoad(false);
            }, 200);
        }
    };
    const getAllCourses = async () => {
        try {
            const All_Courses: any = await Get_All_Course_noOffset();
            if (All_Courses.Status === 1) {
                const allcourses = All_Courses.Courses.map((item: any) => {
                    return {
                        label: item.courseName,
                        value: item.courseId,
                    };
                });
                setCourses([
                    { value: "", label: "Select Course" },
                    ...allcourses,
                ]);
            }
        } catch (err) {
            alert("Something went wrong try again");
            console.error("An error occurred:", err);
        } finally {
            setTimeout(() => {
                setLoad(false);
            }, 200);
        }
    };
    useEffect(() => {
        getAllCourses();
        getAllUsers();
        // eslint-disable-next-line
    }, []);
    // Handle Page Navigation
    function BackButton() {
        Navigate(-1);
    }

    // Form Sumit
    const onSubmit: SubmitHandler<enrollment> = async (data) => {
        setLoad(true);
        try {
            const addUser: any = await Add_enrollment(data);
            if (addUser.Status === 1) {
                Toast(addUser.Message, "success");
                BackButton();
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                SetError(error.response.data.Message);
            } else {
                alert("Something went wrong try again");
                console.error("An error occurred:", error);
            }
        } finally {
            setLoad(false);
        }
    };
    return (
        <Layout Active={"Enroll"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Add Enrollment</title>
            </Helmet>
            {/* <input type="radio" checked name="" id="" /> */}
            <div className={"Container"}>
                <button onClick={BackButton} className={"back"}>
                    Back
                </button>
                <div className={"form_container"}>
                    <h3>Add User </h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                            label="Course"
                            name="courseId"
                            register={register}
                            errors={errors}
                            options={courses}
                        />
                        <FormControl
                            label="Users"
                            name="userId"
                            register={register}
                            errors={errors}
                            options={users}
                        />

                        {error.length > 0 && (
                            <span style={{ color: "red" }}>{error}</span>
                        )}
                        <div className={"submit"}>
                            {load ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                <button>Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcourse;

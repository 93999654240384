import sendRequest from "Service/Base";
import { LoginTypes } from "Utils/Types/Types";

export const Admin_Login = async (credentials: LoginTypes) => {
    try {
        const response = await sendRequest("POST", "admin/login", credentials);
        return response;
    } catch (err) {
        throw err;
    }
};

export const All_Users = async (offset: number) => {
    try {
        const response = await sendRequest(
            "GET",
            `admin/users/all?Offset=${offset}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const All_Users_noOffset = async () => {
    try {
        const response = await sendRequest("GET", `/admin/all-users`);
        return response;
    } catch (err) {
        throw err;
    }
};
export const Add_User = async (body: any) => {
    try {
        const response = await sendRequest("POST", `admin/users`, body);
        return response;
    } catch (err) {
        throw err;
    }
};
export const Edit_User = async (body: any, userId: number) => {
    try {
        const response = await sendRequest(
            "PUT",
            `admin/${userId}/users
        `,
            body
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const Delete_User = async (userId: number) => {
    try {
        const response = await sendRequest(
            "DELETE",
            `admin/${userId}/users
        `
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const Get_All_Courses = async (offset: number) => {
    try {
        const response = await sendRequest(
            "GET",
            `admin/courses?Offset=${offset}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const Get_All_Course_noOffset = async () => {
    try {
        const response = await sendRequest("GET", `admin/courses/all`);
        return response;
    } catch (err) {
        throw err;
    }
};
export const Add_Course = async (body: any) => {
    try {
        const response = await sendRequest("POST", `admin/courses`, body);
        return response;
    } catch (err) {
        throw err;
    }
};
export const Edit_Course = async (body: any, courseId: number) => {
    try {
        const response = await sendRequest(
            "PUT",
            `admin/${courseId}/courses
        `,
            body
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const Delete_Course = async (courseId: number) => {
    try {
        const response = await sendRequest(
            "Delete",
            `admin/${courseId}/courses`
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const Course_Videos = async (courseId: number, offset: number) => {
    try {
        const response = await sendRequest(
            "GET",
            `admin/course/videos?courseId=${courseId}&Offset=${offset}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const Delete_video = async (VideoId: number) => {
    try {
        const response = await sendRequest(
            "DELETE",
            `admin/course/videos/${VideoId}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const Add_video = async (body: any) => {
    try {
        const response = await sendRequest("POST", `admin/course/videos`, body);
        return response;
    } catch (err) {
        throw err;
    }
};
export const edit_video = async (body: any, videoId: number) => {
    try {
        const response = await sendRequest(
            "PUT",
            `admin/course/videos/${videoId}`,
            body
        );
        return response;
    } catch (err) {
        throw err;
    }
};
export const enrollment = async (offset: number) => {
    try {
        const response = await sendRequest(
            "GET",
            `admin/course-enrollments?Offset=${offset}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const Delete_Enrollment = async (enrollmentId: number) => {
    try {
        const response = await sendRequest(
            "DELETE",
            `admin/course-enrollments/${enrollmentId}`
        );
        return response;
    } catch (err) {
        throw err;
    }
};

export const Add_enrollment = async (data: any) => {
    try {
        const response = await sendRequest(
            "POST",
            `/admin/course-enrollments`,
            data
        );
        return response;
    } catch (err) {
        throw err;
    }
};

import S3FileUpload from "react-s3";
import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";
import { BsCloudUpload } from "react-icons/bs";
import { useSearchParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import { useForm, SubmitHandler } from "react-hook-form";
import { AiOutlineCloseCircle } from "react-icons/ai";

import { Toast } from "Utils/util";
import Layout from "Component/Layout";
import { config } from "Utils/s3config";
import MainLoad from "Utils/loader/loader";
import { NullCheck } from "Utils/validator";
import { VideoType } from "Utils/Types/Types";
import FormControl from "Component/reusable/Forminput";
import { Add_video, edit_video } from "Service/ServiceCall";
window.Buffer = window.Buffer || require("buffer").Buffer;

function Addcourse() {
    const Navigate = useNavigate();
    const [params] = useSearchParams();
    // eslint-disable-next-line
    const [edit, setEdit] = useState<Boolean>(false);
    const [load, setLoad] = useState<Boolean>(false);
    const [uploadLoad, setuploadLoad] = useState(false);
    const [uploadFileNames, setUploadFileNames] = useState<any>({
        name: "",
        link: "",
    });
    const [error, SetError] = useState("");
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm<VideoType>();

    useEffect(() => {
        let selectedVideo = sessionStorage.getItem("selectedVideo");
        if (params.get("videoId") && selectedVideo) {
            setEdit(true);
            let parsedData = JSON.parse(selectedVideo);
            reset({
                status: parsedData.status,
                topicName: parsedData.topicName,
            });
            if (parsedData.videoURL != "NA") {
                setUploadFileNames({ name: "", link: parsedData.videoURL });
            }
        }
        // eslint-disable-next-line
    }, []);

    // Handle Page Navigation
    function BackButton() {
        Navigate(-1);
    }
    const fileuploadDesign = (
        <div className={"add-item"}>
            <BsCloudUpload className={"upload-icon"} />
            <h5>Drag Your Files here</h5>
            <p>(Only *.Mp4 will be accepted)</p>
        </div>
    );
    // Form Sumit
    const onSubmit: SubmitHandler<VideoType> = async (data) => {
        setLoad(true);
        let CourseId = Number(params.get("courseId"));
        let videoId = Number(params.get("videoId"));
        let body: any = {
            topicName: data.topicName,
            courseId: CourseId,
        };
        try {
            if (!edit) {
                if (uploadFileNames.link.length === 0) {
                    alert("Video is required");
                    return;
                }
                body = { ...body, videoURL: uploadFileNames.name };
                const Add: any = await Add_video(body);
                if (Add.Status === 1) {
                    Toast(Add.Message, "success");
                    BackButton();
                }
            } else {
                if (uploadFileNames.name.length > 0) {
                    body = { ...body, videoURL: uploadFileNames.name };
                }
                const EditCourse: any = await edit_video(body, videoId);
                if (EditCourse.Status === 1) {
                    sessionStorage.removeItem("selectedVideo");
                    Toast(EditCourse.Message, "success");
                    Navigate(-1);
                }
            }
        } catch (error: any) {
            if (error.response && error.response.status === 400) {
                SetError(error.response.data.Message);
            } else {
                alert("Something went wrong try again");
                console.error("An error occurred:", error);
            }
        } finally {
            setLoad(false);
        }
    };

    const profileUpload = (file: any) => {
        if (file.type === "video/mp4") {
            const timestamp = Date.now();
            let filename = `${timestamp}.${String(file.type).split("/")[1]}`;
            const newfile = new File([file], filename);
            let courseId = params.get("courseId");
            setuploadLoad(true);
            S3FileUpload.uploadFile(newfile, {
                ...config,
                dirName: `course-videos/${courseId}`,
            })
                .then((res: any) => {
                    setuploadLoad(false);
                    setUploadFileNames({
                        link: `${res.location}`,
                        name: `/${courseId}/${filename}`,
                    });
                })
                .catch((err: any) => {
                    setuploadLoad(false);
                    alert(err);
                    console.log(err);
                });
        } else {
            alert("please upload Video in MP4 format ");
        }
    };
    const closeHandler = () => {
        setUploadFileNames({ name: "", link: "" });
    };
    return (
        <Layout Active={"Courses"}>
            {uploadLoad && <MainLoad />}
            <Helmet>
                <meta charSet="utf-8" />
                <title>{edit ? "Update" : "Add"} Video</title>
            </Helmet>
            <div className={"Container"}>
                <button onClick={BackButton} className={"back"}>
                    Back
                </button>
                <div className={"form_container"}>
                    <h3>{!edit ? "Add Video" : "Update Video"}</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormControl
                            label="Topic Name"
                            name="topicName"
                            register={register}
                            errors={errors}
                            validate={NullCheck}
                        />
                        {uploadFileNames.link.length == 0 && (
                            <div className={"video-upload"}>
                                <div>
                                    <FileUploader
                                        classes={"video-upload-input"}
                                        multiple={false}
                                        handleChange={profileUpload}
                                        name="file"
                                        children={fileuploadDesign}
                                    />
                                </div>
                            </div>
                        )}
                        {uploadFileNames.link && (
                            <div className="preview">
                                <label htmlFor="">Video</label>
                                <video controls width="100%" height={"300"}>
                                    <source
                                        src={uploadFileNames.link}
                                        type="video/mp4"
                                    />
                                    Your browser does not support the video tag.
                                </video>
                                <button type="button" onClick={closeHandler}>
                                    <AiOutlineCloseCircle />
                                </button>
                            </div>
                        )}
                        {edit && (
                            <FormControl
                                label="Status"
                                name="status"
                                register={register}
                                errors={errors}
                                validate={NullCheck}
                                options={[
                                    { value: "1", label: "Active" },
                                    { value: "2", label: "inactive" },
                                ]}
                            />
                        )}
                        {error.length > 0 && (
                            <span style={{ color: "red" }}>{error}</span>
                        )}
                        <div className={"submit"}>
                            {load ? (
                                <ReactLoading
                                    color="green"
                                    type="spokes"
                                    height={30}
                                    width={30}
                                />
                            ) : (
                                <button>Submit</button>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </Layout>
    );
}

export default Addcourse;

import { Route, Routes } from "react-router";
import { config } from "./config";
import { Navigate } from "react-router";
function AdminRoutes() {
    const RoleAccess = ({ element, role }) => {
        let AdminData = JSON.parse(localStorage.getItem("AdminData"));

        if (AdminData.role === 1) {
            return element;
        }

        if (AdminData.role === 2 && role == 2) {
            return element;
        }

        return <Navigate to={"/denied"} />;
    };

    return (
        <Routes>
            {config.map((route, index) => (
                <Route
                    key={index}
                    path={route.path}
                    element={
                        <RoleAccess
                            element={<route.Element />}
                            role={route.role}
                        />
                    }></Route>
            ))}
        </Routes>
    );
}

export default AdminRoutes;

import Enrollment from "Pages/Enrollment/enroll/index";

import Users from "Pages/Users/User";
import AddUsers from "Pages/Users/Add";

import Courses from "Pages/Courses/allCourses";
import Addcourse from "Pages/Courses/Addcourse";
import CourseVideos from "Pages/CourseVideos/videos/index";
import VideoEdit from "Pages/CourseVideos/Add/index";
import AddEnrolledCourses from "Pages/Enrollment/Add/index";

export const config = [
    {
        path: "/Courses",
        Element: Courses,
        role: 2,
    },
    {
        path: "/Coursesvideos",
        Element: CourseVideos,
        role: 2,
    },
    {
        path: "/Courses/Add",
        Element: Addcourse,
        role: 2,
    },
    {
        path: "/Courses/edit",
        Element: Addcourse,
        role: 2,
    },
    {
        path: "/Courses/videos/edit",
        Element: VideoEdit,
        role: 2,
    },
    {
        path: "/Courses/videos/add",
        Element: VideoEdit,
        role: 2,
    },
    {
        path: "/Users",
        Element: Users,
        role: 1,
    },
    {
        path: "/Users/Add",
        Element: AddUsers,
        role: 1,
    },
    {
        path: "/Users/edit",
        Element: AddUsers,
        role: 1,
    },
    {
        path: "/Enrollment",
        Element: Enrollment,
        role: 1,
    },
    {
        path: "/Enrollment/Add",
        Element: AddEnrolledCourses,
        role: 1,
    },
];

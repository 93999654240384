import Modal from "react-modal";
import Styles from "./modal.module.scss";

import { AiOutlineClose } from "react-icons/ai";
Modal.setAppElement("#root");

const DemoForm = ({ show, setShow }) => {
    // CLOSE FORM
    const closeModal = () => {
        setShow(false);
    };
    console.log(show);
    return (
        <Modal
            isOpen={show}
            contentLabel="Modal"
            className={Styles["modal"]}
            onRequestClose={closeModal}
            overlayClassName={Styles["overlay"]}>
            <div className={Styles["form-wrapper"]}>
                <video controls width="100%" height={"300"} autoPlay>
                    <source src={show} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </Modal>
    );
};
export default DemoForm;

import { useState, useEffect } from "react";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
import ReactPaginate from "react-paginate";
import { NavLink, useSearchParams } from "react-router-dom";
import { AiOutlinePlus, AiFillDelete } from "react-icons/ai";
import Moment from "react-moment";

import Layout from "Component/Layout";
import Loader from "Pages/Enrollment/enroll/loader";
import { enrollment, Delete_Enrollment } from "Service/ServiceCall";
import useStore from "Store/store";
import { Toast } from "Utils/util";

function Enrollment() {
    const [load, setLoad] = useState(true);
    const [users, setUsers] = useState([]);
    const [params, setParams] = useSearchParams();
    const [pageCount, setpageCount] = useState(1);
    const [showPaginate, setshowPaginate] = useState(false);
    const NoOfRecords = useStore((item) => item.NoOfRecords);
    const [forcepage, setforcepage] = useState(0);

    const enrolledCourses = async (offset: number) => {
        try {
            const all_Users: any = await enrollment(offset);
            if (all_Users.Status === 1) {
                setUsers(all_Users.UserCourseEnrollment);

                if (all_Users.EnrollmentCount > NoOfRecords) {
                    setpageCount(
                        Math.ceil(
                            Number(all_Users?.EnrollmentCount) /
                                Number(NoOfRecords)
                        )
                    );

                    setshowPaginate(true);
                }
            }
        } catch (err) {
            alert("Something went wrong try again");
            console.error("An error occurred:", err);
        } finally {
            setTimeout(() => {
                setLoad(false);
            }, 500);
        }
    };
    useEffect(() => {
        let page = Number(params.get("page"));

        if (page) {
            enrolledCourses((page - 1) * NoOfRecords);
            setforcepage(page - 1);
        }
        // eslint-disable-next-line
    }, []);

    const handlePageChange = (selectedObject: any) => {
        setParams({ page: selectedObject.selected + 1 });
        window.scrollTo({ top: 10, behavior: "smooth" });
        setforcepage(selectedObject.selected);
        enrolledCourses(selectedObject.selected * NoOfRecords);
        setLoad(true);
    };
    async function DeleteCourse(CourseId: number) {
        try {
            const Delete: any = await Delete_Enrollment(CourseId);
            if (Delete.Status === 1) {
                Toast(Delete.Message, "success");
                let page = Number(params.get("page"));
                if (users.length === 1) {
                    setParams({ page: String(page - 1) });
                    page = (page - 2) * NoOfRecords;
                    setforcepage(page - 2);
                } else {
                    setforcepage(page - 1);
                    page = (page - 1) * NoOfRecords;
                }

                enrolledCourses(page);
            }
        } catch (err) {
            alert("something went Wrong try Aagin");
        }
    }

    const deleteHandler = (event: React.MouseEvent) => {
        let id = event.currentTarget.getAttribute("custom-id");
        swal("Are you sure you want to Delete Course", {
            icon: "warning",
            dangerMode: true,
            buttons: {
                catch: {
                    text: "Delete",
                    value: "Complete",
                    className: "sweet-alert-btn",
                },
                defeat: false,
            },
        }).then((value: any) => {
            switch (value) {
                case "Complete":
                    DeleteCourse(Number(id));
                    break;
            }
        });
    };
    return (
        <Layout Active={"Enroll"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Enrollment</title>
            </Helmet>
            <div className={"Container"}>
                <NavLink to={"/Admin/Enrollment/Add"}>
                    {" "}
                    <AiOutlinePlus />
                    <span>New Enrollment</span>
                </NavLink>
                <div className={"sub-wrapper"}>
                    <h3>Enrollment</h3>
                    {load ? (
                        <Loader />
                    ) : (
                        <div className="Table">
                            <table>
                                <thead>
                                    <tr>
                                        <th> Name</th>
                                        <th>Mobile</th>
                                        <th>Course </th>
                                        <th>Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {users.length > 0 ? (
                                        users.map((enroll: any, id: number) => (
                                            <tr key={id}>
                                                <td>{enroll.firstName}</td>
                                                <td>{enroll.mobileNumber}</td>
                                                <td>{enroll.courseName}</td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {
                                                            new Date(
                                                                enroll.createdAt
                                                            )
                                                        }
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <div
                                                        className={
                                                            "Action-buttons"
                                                        }>
                                                        <button
                                                            onClick={
                                                                deleteHandler
                                                            }
                                                            custom-id={
                                                                enroll.courseEnrollmentId
                                                            }>
                                                            <AiFillDelete
                                                                size={20}
                                                                color="red"
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={5}>No Data Found</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {showPaginate && (
                        <div
                            style={{
                                justifySelf: "right",
                            }}>
                            <ReactPaginate
                                pageCount={pageCount}
                                // pageRange={2}
                                forcePage={forcepage}
                                marginPagesDisplayed={2}
                                onPageChange={handlePageChange}
                                breakClassName={"page-item"}
                                breakLinkClassName={"page-link"}
                                containerClassName={"pagination"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                activeClassName={"active"}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default Enrollment;

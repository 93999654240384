import { useForm, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router";

import Login_Left_Bg from "Assets/loginimg.jpg";
import { LoginTypes } from "Utils/Types/Types";
import LoginStyles from "Pages/Login/loginStyles.module.scss";
import { Admin_Login } from "Service/ServiceCall";
import { api } from "Service/Base";
import { useState } from "react";
function Login() {
    const Navigate = useNavigate();
    const [load, setLoad] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm<LoginTypes>();

    const onSubmit: SubmitHandler<LoginTypes> = async (data) => {
        setLoad(true);
        try {
            const LoginData: any = await Admin_Login(data);
            if (LoginData.Status === 1) {
                localStorage.setItem("authToken", LoginData.Token);
                localStorage.setItem(
                    "AdminData",
                    JSON.stringify(LoginData.adminDetails)
                );
                api.defaults.headers.common[
                    "Authorization"
                ] = `Bearer ${LoginData.Token}`;
            }
            Navigate("admin/Courses?page=1");
        } catch (error: any) {
            setLoad(false);
            if (error.response && error.response.status === 400) {
                setError(
                    "password",
                    { type: "manual", message: error.response.data.Message },
                    {
                        shouldFocus: false,
                    }
                );
            } else {
                alert("Something went wrong try again");
                console.error("An error occurred:", error);
            }
        }

        // NAVIGATE("/Admin/Courses");
    };

    return (
        <div className={LoginStyles["Main_Container"]}>
            <div className={LoginStyles["Container"]}>
                <img src={Login_Left_Bg} alt="" />
                <div className={LoginStyles["login-form"]}>
                    <h3>Login</h3>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className={LoginStyles["form-control"]}>
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                {...register("emailId", {
                                    required: "",
                                })}
                            />
                            {errors?.emailId && (
                                <p className={LoginStyles.error}>
                                    {errors?.emailId?.message}
                                </p>
                            )}
                        </div>
                        <div className={LoginStyles["form-control"]}>
                            <label htmlFor="password">Password</label>

                            <input
                                type="password"
                                {...register("password", {
                                    required: "Password is required!",
                                })}
                            />
                            {errors?.password && (
                                <p className={LoginStyles.error}>
                                    {errors?.password?.message}
                                </p>
                            )}
                        </div>
                        <button
                            type="submit"
                            disabled={load}
                            className={load ? LoginStyles["load"] : ""}>
                            Login{" "}
                        </button>
                        <span className={LoginStyles.border}></span>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
